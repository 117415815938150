import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoIcon from '../../images/svg/LogoIconOEG.svg';
import Button from '../Button';

const Header = () => (
  <header className="sticky top-0 bg-white shadow">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <div className="w-12 mr-3">
          <img src={LogoIcon} />
        </div>
        Oil & Games
      </div>
      <div className="flex mt-4 sm:mt-0">
        <AnchorLink className="px-4" href="#services">
          Serviços
        </AnchorLink>
        <AnchorLink className="px-4" href="#features">
          Projetos
        </AnchorLink>
        <AnchorLink className="px-4" href="#stats">
          Estatísticas
        </AnchorLink>
        <AnchorLink className="px-4" href="#testimonials">
          Quem Somos
        </AnchorLink>
      </div>
      <div className="hidden md:block">
        <Button className="text-sm" href="/.auth/login/aad">Login </Button>
      </div>
    </div>
  </header>
);

export default Header;
